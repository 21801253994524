@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer components {
  .container {
    /* @apply w-3/4 mx-auto py-10 px-32; */
    /* @apply max-w-7xl mx-auto px-4 md:px-6 lg:px-20 xl:px-20 py-10; */
    @apply max-w-7xl lg:max-w-full 2xl:max-w-7xl mx-auto px-4 lg:px-52 2xl:px-4 py-5;
    /* @apply max-w-7xl mx-auto px-4 py-5; */
  }
}

@layer utilities {
}
