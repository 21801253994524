#vertical_menu .active {
  border-bottom: 0px solid #37c5a8;
  color: #37c5a8;
}

#vertical_menu .active::before {
  content: "";
  background-color: #37c5a8;
  width: 6px;
  height: 12px;
  position: absolute;
  left: -6%;
  top: 22%;
}

#vertical_menu a {
  cursor: pointer;
  position: relative;
}

/* Blog Slide  */
#blogslide .r-img {
  position: relative;
}

#blogslide .gg-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#blogslide .gg-chevron-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 18px;
  top: 3px;
}

#blogslide .gg-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#blogslide .gg-chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: -7px;
  top: 3px;
}

#blogslide .owl-next {
  position: absolute;
  right: 0px;
  top: 45%;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #fff !important;
  display: block !important;
  border: 1px solid transparent !important;
  line-height: 50px;
  text-align: center;
  transition: all 500ms ease;
  opacity: 0 !important;
}

#blogslide:hover .owl-next {
  opacity: 1 !important;
}

.owl-custom .owl-nav .owl-prev {
  position: absolute;
  left: 0px;
  top: 45%;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #fff !important;
  display: block !important;
  line-height: 50px;
  text-align: center;
  transition: all 500ms ease;
  opacity: 0 !important;
}

.owl-custom .owl-nav .owl-prev:hover {
  background-color: #37c5a8 !important;
}

.owl-custom:hover .owl-nav .owl-prev,
.owl-nav .owl-next {
  opacity: 1 !important;
}

#blogslide .owl-next:hover {
  background-color: #37c5a8 !important;
}

/* explore Slide  */
#explore .r-img {
  position: relative;
}

#explore .gg-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#explore .gg-chevron-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 18px;
  top: 3px;
}

#explore .gg-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#explore .gg-chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: -7px;
  top: 3px;
}

#explore .owl-next {
  position: absolute;
  right: 0px;
  top: 25%;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #fff !important;
  display: block !important;
  border: 1px solid transparent !important;
  line-height: 50px;
  text-align: center;
  transition: all 500ms ease;
  opacity: 0 !important;
}

#explore:hover .owl-next {
  opacity: 1 !important;
}

.owl-explore .owl-nav .owl-prev {
  position: absolute;
  left: 0px;
  top: 25%;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #fff !important;
  display: block !important;
  line-height: 50px;
  text-align: center;
  transition: all 500ms ease;
  opacity: 0 !important;
}

.owl-explore .owl-nav .owl-prev:hover {
  background-color: #37c5a8 !important;
}

.owl-explore:hover .owl-nav .owl-prev,
.owl-nav .owl-next {
  opacity: 1 !important;
}

#explore .owl-next:hover {
  background-color: #37c5a8 !important;
}

/* explore Slide  */
#testimonials .r-img {
  position: relative;
}

#testimonials .gg-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#testimonials .gg-chevron-left::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 18px;
  top: 3px;
}

#testimonials .gg-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#testimonials .gg-chevron-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: -7px;
  top: 3px;
}

#testimonials .owl-next {
  position: absolute;
  right: 0px;
  top: 40%;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #fff !important;
  display: block !important;
  border: 1px solid transparent !important;
  line-height: 50px;
  text-align: center;
  transition: all 500ms ease;
  opacity: 0 !important;
}

#testimonials:hover .owl-next {
  opacity: 1 !important;
}

.owl-testimonials .owl-nav .owl-prev {
  position: absolute;
  left: 0px;
  top: 40%;
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #fff !important;
  display: block !important;
  line-height: 50px;
  text-align: center;
  transition: all 500ms ease;
  opacity: 0 !important;
}

.owl-testimonials .owl-nav .owl-prev:hover {
  background-color: #37c5a8 !important;
}

.owl-testimonials:hover .owl-nav .owl-prev,
.owl-nav .owl-next {
  opacity: 1 !important;
}

#testimonials .owl-next:hover {
  background-color: #37c5a8 !important;
}

.ril-close {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=")
    no-repeat center !important;
}

.custom-body {
  position: relative;
  height: 100vh;
}
